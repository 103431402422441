import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_p = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - P'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>P</h2>
     <LatQuotesIntro />
    
     <p><b>Pace tua</b> - With your consent</p>
<p><b>Pace</b> - By leave of</p>
<p><b>Pacta sunt servanda</b> - Agreements are to be kept. (Cicero)</p>
<p><b>Pactum serva</b> - Keep the faith</p>
<p><b>Pallida mors</b> - Pale Death. (Horace)</p>
<p><b>Palmam qui meruit ferat</b> - Let him who has earned it bear the reward</p>
<p><b>Panem et circenses</b> - Bread and circuses. Food and games to keep people happy. (Juvenalis)</p>
<p><b>Par pare refero</b> - I return like for like tit for tat retaliation</p>
<p><b>Parens patriae</b> - Parent of the country</p>
<p><b>Pares cum paribus</b> - Like persons with like persons. Birds of a feather flock together</p>
<p><b>Pari passu</b> - With equal pace</p>
<p><b>Pars maior lacrimas ridet et intus habet</b> - You smile at your tears but have them in your heart. (Martialis)</p>
<p><b>Particeps criminis</b> - Partner in crime</p>
<p><b>Parturient montes, nascetur ridiculus mus</b> - Mountains will be in labour, and an absurd mouse will be born. (all that work and nothing to show for it)</p>
<p><b>Parva leves capiunt animas</b> - Small things occupy light minds (small things amuse small minds)</p>
<p><b>Parva scintilla saepe magnam flamam excitat</b> - The small sparkle often initiates a large flame</p>
<p><b>Passim</b> - All through</p>
<p><b>Pater familias</b> - Father of the family</p>
<p><b>Pater historiae</b> - The father of history</p>
<p><b>Pater noster</b> - Our Father (The first words of the Lord's Prayer in Latin)</p>
<p><b>Pater patriae</b> - Father of the country</p>
<p><b>Patria est communis omnium parens</b> - Our native land is the common parent of us all. (Cicero)</p>
<p><b>Patris est filius</b> - He is his father's son</p>
<p><b>Paucis verbis</b> - In a few words</p>
<p><b>Paupertas omnium artium repertrix</b> - Poverty [is the] inventor of all the arts necessity is the mother of invention</p>
<p><b>Pax et bonum!</b> - Peace and salvation!</p>
<p><b>Pax tecum</b> - May peace be with you (Singular)</p>
<p><b>Pax vobiscum</b> - May peace be with you (Plural)</p>
<p><b>Pax</b> - Peace</p>
<p><b>Peccatum tacituritatis</b> - Sin of silence</p>
<p><b>Peccavi</b> - I have sinned</p>
<p><b>Pecunia non olet</b> - Money has no smell. Money doesn't stink. (don't look a gift horse in the mouth) (Vespasianus)</p>
<p><b>Pecuniate obediunt omnia</b> - All things obey money</p>
<p><b>Pede poena claudo</b> - Punishment comes limping. Retribution comes slowly, but surely. (Horace)</p>
<p><b>Pendente lite</b> - While a suit is pending</p>
<p><b>Penetralia mentis</b> - The innermost recesses of the mind. Heart of hearts</p>
<p><b>Per accidens</b> - By accident</p>
<p><b>Per angusta in augusta</b> - Through difficulties to great things</p>
<p><b>Per annum (p.a.)</b> - Yearly</p>
<p><b>Per ardua ad astra</b> - Through difficulties to the stars</p>
<p><b>Per aspera ad astra</b> - Through the thorns to the stars</p>
<p><b>Per capita</b> - Per head</p>
<p><b>Per cent (per centum)</b> - Per hundred</p>
<p><b>Per contra</b> - On the contrary</p>
<p><b>Per diem</b> - Per day; daily allowance</p>
<p><b>Per fas et nefas</b> - Through right or wrong</p>
<p><b>Per impossibile</b> - As is impossible a way to qualify a proposition that cannot ever be true</p>
<p><b>Per mensem</b> - Monthly</p>
<p><b>Per procurationem (per pro)</b> - By delegation to</p>
<p><b>Per se</b> - By or in itself</p>
<p><b>Per varios usus artem experientia fecit</b> - Through different exercises practice has brought skill. (Manilius)</p>
<p><b>Perfer et obdura; dolor hic tibi proderit olim</b> - Be patient and tough; some day this pain will be useful to you. (Ovid)</p>
<p><b>Periculum in mora</b> - There is danger in delay. (Livy)</p>
<p><b>Perpetuo vincit qui utitur clementia</b> - He is forever victor who employs clemency. (Syrus)</p>
<p><b>Perpetuum mobile</b> - Perpetual motion</p>
<p><b>Persona (non) grata</b> - (un)welcome person</p>
<p><b>Pessimum genus inimicorum laudantes</b> - Flatterers are the worst type of enemies</p>
<p><b>Pessimus inimicorum genus, laudantes</b> - The worst kind of enemies, those who can praise. (Tacitus)</p>
<p><b>Petitio principii</b> - An assumption at the start</p>
<p><b>Philosophum non facit barba!</b> - The beard does not define a philosopher. (Plutarch)</p>
<p><b>Pictor ignotus</b> - Painter unknown</p>
<p><b>Pinxit</b> - He/she painted it</p>
<p><b>Placebo</b> - I will please. Medical expression for remedies with no medical effect, which improve one's medical condition only because one believes they do</p>
<p><b>Placet</b> - It pleases</p>
<p><b>Pleno iure</b> - With full authority</p>
<p><b>Pluralitas non est ponenda sine neccesitate</b> - Entities should not be multiplied unnecessarily (Ockham)</p>
<p><b>Plusque minusque</b> - More or less</p>
<p><b>Poeta nascitur, non fit</b> - The poet is born, not made</p>
<p><b>Pone ubi sol non lucet!</b> - Put it where the sun don't shine!</p>
<p><b>Possunt quia posse videntur</b> - They can because they think they can</p>
<p><b>Post bellum</b> - After the war</p>
<p><b>Post coitem</b> - After sexual intercourse</p>
<p><b>Post factum</b> - After the fact</p>
<p><b>Post hoc ergo propter hoc</b> - After this, therefore because of this</p>
<p><b>Post hoc</b> - After this</p>
<p><b>Post meridiem (p.m.)</b> - After midday</p>
<p><b>Post mortem</b> - After death. (nowadays, the autopsy performed by a coroner)</p>
<p><b>Post obitum</b> - After death</p>
<p><b>Post partum</b> - After childbirth</p>
<p><b>Post proelia praemia</b> - After the battles come the rewards</p>
<p><b>Post scriptum (ps)</b> - After what has been written</p>
<p><b>Post tenebras lux</b> - After the darkness, light</p>
<p><b>Potest ex casa magnus vir exire</b> - A great man can come from a hut. (Seneca)</p>
<p><b>Potius mori quam foedari</b> - Rather to die than to be dishonoured (death before dishonour)</p>
<p><b>Potius sero quam numquam</b> - It's better late than never. (Livy)</p>
<p><b>Praemonitus, praemunitus</b> - Forewarned, forearmed</p>
<p><b>Praetio prudentia praestat</b> - Prudence supplies a reward</p>
<p><b>Prima facie</b> - At first sight; on the face of it. (in law, an obvious case that requires no further proof)</p>
<p><b>Primum mobile</b> - Prime mover</p>
<p><b>Primum non nocere</b> - The first thing is to do no harm. (Hippocratic oath)</p>
<p><b>Primum viveri deinde philosophari</b> - Live before you philosophize, or Leap before you look</p>
<p><b>Primus inter pares</b> - First among equals</p>
<p><b>Principiis obsta</b> - Resist the beginnings</p>
<p><b>Pro bono (pro bono publico)</b> - For the good of the public</p>
<p><b>Pro di immortales!</b> - Good Heavens!</p>
<p><b>Pro et contra</b> - For and against</p>
<p><b>Pro forma</b> - As a matter of formality</p>
<p><b>Pro hac vice</b> - For this occaision</p>
<p><b>Pro memoria</b> - For a memorial</p>
<p><b>Pro nunc</b> - For now</p>
<p><b>Pro opportunitate</b> - As circumstances allow</p>
<p><b>Pro patria</b> - For one's country</p>
<p><b>Pro rata</b> - In proportion to the value. (per hour for example)</p>
<p><b>Pro re nata (prn)</b> - For an occasion as it arises</p>
<p><b>Pro se</b> - On one's own behalf</p>
<p><b>Pro tanto</b> - So far</p>
<p><b>Pro tempore (pro tem.)</b> - For the time being</p>
<p><b>Probae esti in segetem sunt deteriorem datae fruges, tamen ipsae suaptae enitent</b> - A good seed, planted even in poor soil, will bear rich fruit by its own nature. (Accius)</p>
<p><b>Probatum est</b> - It has been proved</p>
<p><b>Probitas laudatur et alget</b> - Honesty is praised and left in the cold. (Juvenal)</p>
<p><b>Promotor fidei</b> - Promoter of the faith</p>
<p><b>Proprium humani ingenii est odisse quem laeseris</b> - It is human nature to hate a person whom you have injured</p>
<p><b>Proxime accessit</b> - He/she came close</p>
<p><b>Proximo (prox.)</b> - Of the next month</p>
<p><b>Proximus sum egomet mihi</b> - I am closest to myself. (Charity begins at home.) (Terence)</p>
<p><b>Pueri pueri, pueri puerilia tractant</b> - Children are children, (therefore) children do childish things</p>
<p><b>Pulvis et umbra sumus</b> - We are dust and shadow. (Horace)</p>
<p><b>Puri sermonis amator</b> - A lover of pure speech. (Terence)</p>
<p><b>Puris omnia pura</b> - To the pure all things are pure</p>

   </Layout>
  )
}

export default LatQuotes_p
